<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="handleSubmit">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{
                $gettext(
                  "It looks like you are no longer a member of a team. Please create your own team or contact us if you believe this is an error."
                )
              }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <FormInput
                class="col-md-12"
                v-model="payload.name"
                :label="$gettext('Team name')"
                field="name"
              />
            </div>
            <div class="row">
              <FormGroupMultiselect
                class="col-md-12"
                field="team_size"
                :label="$gettext('What is the size of your organization?')"
                :options="teamSizeOptions"
                :multiple="false"
                v-model="team_size"
                required
              />
            </div>
            <div class="row">
              <FormGroupCheckbox
                class="col-md-12"
                :label="$gettext('We are a recruitment/placement firm')"
                field="is_agency"
                v-model="payload.is_agency"
              />
            </div>
          </div>
          <div class="card-footer">
            <SubmitButton :label="$gettext('Save')" class="btn btn-info" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import SubmitButton from "@/components/SubmitButton";
import FormGroupCheckbox from "@/components/FormGroups/FormGroupCheckbox.vue";
import FormGroupMultiselect from "@/components/FormGroups/FormGroupMultiselect.vue";

export default {
  components: {
    FormGroupMultiselect,
    FormGroupCheckbox,
    FormInput,
    SubmitButton
  },
  data: () => ({
    payload: { name: "", is_agency: false },
    teamSizes: [],
    team_size: {}
  }),
  created() {
    this.$http
      .get("ats/team-sizes")
      .then(({ data }) => (this.teamSizes = data));
  },
  computed: {
    ...mapGetters("me", ["lg"]),
    teamSizeOptions() {
      return this.teamSizes.map(teamSize => ({
        value: teamSize.id,
        label: teamSize.label[this.lg]
      }));
    }
  },
  methods: {
    ...mapActions("teams", ["create"]),
    ...mapMutations("me", ["setUser"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      this.payload.team_size = this.team_size.value;
      this.submit(() => this.create(this.payload)).then(({ data }) => {
        this.setUser(data);
        this.$router.push({
          name: "dashboard"
        });
      });
    }
  }
};
</script>
